import React from "react"
import clsx from "clsx"

import classes from "./RoundedButton.module.css"

export default function RoundedButton({
  className,
  color,
  component,
  ...rest
}) {
  const Component = component || "button"

  const rootClassName = clsx(classes.root, classes[color], className)

  return <Component className={rootClassName} {...rest} />
}
