import React, { useState } from "react"

import Root from "../components/root"
import classes from "./about.module.css"

import Logo from "../assets/web_resources/logo-white.png"

import RoundedButton from "../components/RoundedButton"
import { graphql } from "gatsby"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const AboutPage = ({ data }) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)
  const members = data.allContentfulTeamMember.nodes

  return (
    <Layout>
      <Root className={classes.root}>
        <div className="relative xl:max-w-6xl mx-auto w-11/12 max-w-lg xl:w-full">
          {isDropdownOpen && <Dropdown white3={true} />}
          {!isDropdownOpen && (
            <div
              className={classes.logo}
              onClick={e => setisDropdownOpen(!isDropdownOpen)}
            >
              <img src={Logo} alt="" />
            </div>
          )}

          <div onClick={e => setisDropdownOpen(false)}>
            <div className="xl:my-16 text-center max-w-sm xl:max-w-6xl mx-auto">
              <div className={classes.aboutTheMadjon}>ABOUT THE MADJON</div>
              <div className={classes.aboutTheMadjon}>ABOUT THE MADJON</div>
            </div>

            <div className="font-secondary text-sm lg:text-4xl text-center mt-8 -mb-2 lg:-mb-8 ">
              "
            </div>
            <div className="font-secondary text-sm xl:text-4xl italic ">
              We are, as a species, addicted to story. Even when the body goes
              to sleep, the mind stays up all night, telling itself stories.
            </div>
            <div className="hidden xl:block mt-4 lg:mt-0 font-primary text-xs lg:text-xl text-right opacity-50 ">
              -Jonathan Gottschall, The Storytelling Animal
            </div>

            <div className="flex flex-col lg:flex-row">
              <div className="w-full xl:w-1/2 lg:pr-0 mt-16 mb-16">
                <div className="relative">
                  <div className={classes.philossophiaBack}></div>
                  <div className={classes.philossophia}>
                    <div className="mb-4">PHILOS</div>
                    <div>SOPHIA</div>
                  </div>
                  <div className="relative pl-32 lg:pl-48 flex flex-col justify-between font-medium">
                    <div className="text-xs md:text-lg xl:text-xl">
                      No story is like another, therefore no edit should be like
                      another.
                      <br />
                      <br />
                      Uniqueness & individuality inspirecustom and personal
                      edits.
                      <br />
                      <br />
                      Edits are driven by your emotion. No detail is too small.
                      <br />
                      <br />
                      No detail is too small.
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden xl:block w-full lg:w-1/2 lg:pl-8 mt-24 lg:mt-64">
                <div className="flex justify-center mb-16 lg:mb-24">
                  <div className="relative">
                    <div className={classes.weBack}></div>
                    <div className={classes.we}>WE</div>

                    <div className="relative pl-20 flex flex-col justify-between">
                      <div className="text-3xl ">
                        dream <br />
                        edit <br />
                        create
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.weAre}>We are ...</div>

            <p className="text-sm xl:text-xl opacity-75 mt-16">
              ...cinephiles. We developed our analytical eye and knowledge by
              studying cinematography & breaking down every video or movie we
              see. We never stop exploring and gaining knowledge, especially
              during these times when everything is changing rapidly, and videos
              play a crucial part in our creative lives. <br />
              <br />
              <br /> We worked for many international editing services, and
              their business model made us miserable. Because we are passionate
              about our work, we didn't accept being stuck in a system that
              didn’t encourage creativity and promoted quantity over quality.{" "}
              <br /> So we created our own universe! <br />
              <br />
              <br /> Storytelling is not just editing from a technical point of
              view. Storytelling uses a range of different cinematography
              techniques, that when combined, transmit certain emotions. <br />
              Nowadays, everybody can stitch clips together and have a nice
              soundtrack on it, but few are those that can actually tell a
              visual story. <br />
              <br />
              <br /> In the past five years since working in this field, we have
              observed that video editing outside cinematography is made poorly.
              Storytelling is spread just as a concept because the majority has
              no idea what it is. <br />
              <br />
              <br /> Our inner passion moves us forward in creating a community
              of like-minded people. <br /> The goal is to educate editors in
              the spirit of conscious storytelling and refresh a market filled
              with second-hand edits.
            </p>

            <div className="xl:hidden w-full xl:w-1/2 xl:pl-8 mt-24 xl:mt-64">
              <div className="flex justify-center mb-16 xl:mb-24">
                <div className="relative">
                  <div className={classes.weBack}></div>
                  <div className={classes.we}>WE</div>

                  <div className="relative pl-20 flex flex-col justify-between">
                    <div className="text-3xl ">
                      dream <br />
                      edit <br />
                      create
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-12 xl:my-24 text-center max-w-sm xl:max-w-6xl mx-auto">
              <div className={classes.independentStorytellers}>
                INDEPENDENT STORYTELLERS
              </div>
              <div className={classes.independentStorytellers}>
                INDEPENDENT STORYTELLERS
              </div>
            </div>

            <div className="flex flex-col xl:flex-row">
              {members.map(member => (
                <div className="flex mb-8 xl:w-1/2 xl:mx-2">
                  <img
                    className={classes.meetBack}
                    src={member.photo?.fluid.src}
                  />
                  <div className="text-sm ml-4">
                    <div className="mb-4 xl:mb-8">{member.title}</div>
                    <div className="text-xs lg:text-sm">
                      "{member.description.description}"
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-16 lg:text-xl">
              <RoundedButton className={classes.getQuote} color="secondary">
                <AnchorLink to="/video-editing#contactus" stripHash>
                  WORK WITH US!
                </AnchorLink>
              </RoundedButton>
            </div>
          </div>
        </div>
      </Root>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    allContentfulPortfolio(sort: { fields: portfolioId }) {
      nodes {
        id
        title
        thumbnail {
          fluid(maxWidth: 320) {
            src
          }
        }
      }
    }
    allContentfulTeamMember(sort: { fields: memberId }) {
      nodes {
        title
        description {
          description
        }
        photo {
          fluid(maxWidth: 240) {
            src
          }
        }
      }
    }
  }
`

export default AboutPage
